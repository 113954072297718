//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++


#wrapper
  @include default-transition
  display: grid
  grid-template-rows: auto 1fr

#main
  display: grid
  grid-template-columns: auto 1fr
  overflow: auto

  &.nomenus
    overflow: hidden

  &.nosidebar
    grid-template-columns: auto

#content-wrapper
  @include default-transition
  @include styled-scroll-bar
  margin: 0 0 0 0
  padding: 0
  // Needed for Safari
  height: calc(100vh - var(--header-height))
  overflow-y: auto
  overflow-x: hidden
  background-color: var(--body-background)
  position: relative


// ----------- BEGIN Content definition --------------

// The content is structured as grid and should ideally look like this
// --------------------------------------------------------
// |             content-header           |               |
// ---------------------------------------|               |
// |                                      |               |
// |                                      |               |
// |                                      |               |
// |             content-body             |   content-    |
// |                                      |   bodyRight   |
// |                                      |               |
// |                                      |               |
// |                                      |               |
// --------------------------------------------------------

// This layout is designed to show the WP split screen on any rails page.
// Because of that, there are three things to keep in mind
//    1. The content-bodyRight is optional. If it is not shown, the content-body and content-header should fill the available space.
//    2. The WP split screen requires a 100% layout, meaning that the content-bodyRight needs to be
//       "glued" to the right side and the bottom of the screen without any additional paddings or margins.
//    3. Not all pages already follow that desired structure. Older pages render all their content inside content-body.
//       The design most cover that case in which the content-body needs to span the whole available height and width.

// Because of those requirements, the #content element cannot take care of the paddings.
// Instead the children elements have to do that themselves depending on which elements are present

$top-space: 10px
$right-space: 16px
$bottom-space: 10px
$left-space: 16px

$left-side-min-width: 300px

#content
  display: grid
  grid-template-columns: minmax($left-side-min-width, 1fr) auto
  grid-template-rows: auto 1fr
  grid-template-areas: "header bodyRight" "body bodyRight"
  padding: 0
  margin: 0
  width: 100%
  height: 100%
  overflow: auto
  @include styled-scroll-bar
  z-index: 10
  background-color: var(--body-background)

  // Basically if the content-BodyRight is filled:
  // Apply the user-defined split screen width but be able to shrink in case the screen is not wide enough
  &:has(#content-bodyRight > *)
    grid-template-columns: minmax($left-side-min-width, 1fr) minmax(550px, var(--split-screen-width))

  &.content--split
    overflow: hidden

#content-header
  grid-area: header
  padding-right: $right-space
  padding-top: $top-space
  padding-left: $left-space

#content-body
  grid-area: body
  padding-bottom: $bottom-space
  padding-right: $right-space
  padding-left: $left-space
  // Limit the width of the body to the container around it
  overflow-x: hidden

  // Special rules for pages that still follow the old layout and render everything inside the content-body
  .accessibility-helper + &
    padding-top: $top-space
  &:last-child
    padding-right: $right-space

  .content--split &
    overflow: auto
    @include styled-scroll-bar

#content-bodyRight
  grid-area: bodyRight
  padding: 0
  overflow: auto
  @include styled-scroll-bar

// ----------- END Content definition --------------
